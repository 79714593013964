@import "colors";

/* Mixins ------------------------------------------------------------------- */

/* 
Multi-Line Text Ellipse Overflow

Source:
http://hackingui.com/front-end/a-pure-css-solution-for-multiline-text-truncation/
*/
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white){
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount; 
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;

  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}

/* Browser-Specific Hacks --------------------------------------------------- */

/*
Note -- Chrome appears to overwrite this anyway so fuck me I guess.

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus
input:-webkit-autofill, 
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: transparent!important;
}
*/

/* Global Elements ---------------------------------------------------------- */

html {
  font-size: 27px;
}

body {
  background-color: #f8f9fa;
  font-family: 'Source Sans Pro', sans-serif;
}

form {
  // border-radius: 0;
}

/* Global Classes ----------------------------------------------------------- */

.form-control {
  // border-radius: 0;
}

.btn-primary {
  // background-color: $cc-color-continuity-pulse;
  // border-color: $cc-color-continuity-pulse;
}

.tooltip {
  .tooltip-inner {
    font-size: 16px;
    max-width: 12rem;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.fade {
  opacity: 0;
  transition: opacity 0.6s linear;
}

.form-signin {
  .remember-me {
    input {
      width: auto;
      margin-left: 6px;
    }

    label {
      font-size: 16px;
      margin: 0;
      margin-left: 6px;
    }
  }
}

.messages {
  display: none;
}

.pagination {
  .page-item {
    .page-link {
      &.disabled {
        user-select: none;
      }

      .feather {
        vertical-align: baseline;
      }
    }
  }
}

.btn {
  //border-radius: 0;
  font-weight: 300;
}

.btn-primary {
  background-color: $cc-color-continuity-pulse;
  border-color: $cc-color-continuity-pulse;

  &:hover {
    background-color: $cc-color-continuity-pulse-lighter-1;
    border-color: $cc-color-continuity-pulse-lighter-1;
  }
}

.btn-primary.focus,
.btn-primary:focus,
.btn-success.focus,
.btn-success:focus {
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.cc-accordion {
  margin: 2em 1em;

  .card {
    border-radius: 0;
    margin: 0.5em 0;

    .cc-accordion-wrapper {
      padding-top: 0.25rem;
      color: $dark-text;
      padding: 0;
      margin: 0;
      border: 0;

      &:hover {
        background-color: $accordion-wrapper-hover-color;
      }

      &.btn-link {
        &:hover {
          text-decoration: none;
        }

        &:focus {
          text-decoration: none;
        }
      }
    }

    .card-header {
      border-radius: 0;
      padding: 0.25em 1em;

      .cc-accordion-checkbox {
        display: inline-block;

        .btn {
          width: 1.5em;
          height: 1.5em;
          border-radius: 50%;
          padding: 0;
          background: $light-background;
          border: 1px solid $accordion-button-border-color;

          .oi-check {
            color: $accordion-button-border-color;
            top: 3px;
          }

          &:hover {
            background-color: $accordion-button-hover-background-color;
            border-color: $accordion-button-hover-color;
            color: $accordion-button-hover-color;
            fill: $accordion-button-hover-color;
            cursor: pointer;

            .oi-check {
              color: $accordion-button-hover-color;
            }
          }

          &.cc-btn-ticket-closed {
            background: $accordion-button-closed-color;
            border-color: $accordion-button-closed-color;
            fill: $light-background;

            .oi-check {
              color: $light-background;
            }
          }
        }
      }

      .cc-accordion-header {
        font-weight: 300;
        font-size: 1rem;
        padding: 0.5em 1em;
      }
  
      &:first-child {
        border-radius: 0;
      }
    }

    .card-body {
      li {
        .btn {
          margin-bottom: 1rem;
        }
      }
    }
  }
}

.cc-single-container {
  background-color: $light-background;
  box-shadow: $standard-shadow;
  padding: 0;

  .cc-single-container-header {
    padding: 15px;
    background-color: $cc-color-continuity-pulse;
    color: $light-text;
  }

  .cc-single-container-body {
    padding: 15px;
  }
}

.cc-card {
  box-shadow: $standard-shadow;
  border-radius: 0;
  margin: 1em 0;

  &.cc-document {
    &.card {
      height: calc(100% - 2em);

      .card-body {
        .card-title {
          font-weight: 300;
          margin-bottom: calc(1.25rem + 63px);
        }
  
        .btn {
          position: absolute;
          left: 1.25rem;
          right: 1.25rem;
          bottom: 1.25rem;
          width: auto;
        }
      }
    }
  }
  
  &.card {
    .card-body {
      .card-title {
        margin-bottom: 1.25rem;
      }

      p {
        margin-top: 1em;
      }

      .btn {
        width: 100%;
      }
    }

    &.cc-project,
    &.cc-user,
    &.cc-organization,
    &.cc-normalized-height-card {
      height: calc(100% - 2em);
      padding-bottom: calc(4em + 2px);

      .btn {
        position: absolute;
        left: 1.25rem;
        right: 1.25rem;
        bottom: 1.25rem;
        width: auto;
      }
    }
  }

  .cc-card-image {
    img {
      display: block;
      margin: 0 auto;
      padding-top: 1.2em;
      padding-bottom: 1.2em;
      width: 128px;
    }

    svg {
      display: block;
      margin: 0 auto;
      padding: 0;
      width: 128px!important;
      height: 128px!important;
    }
  }
}

.cc-fade-card,
.cc-slide-card {
  display: none;
}

/* Navbar ------------------------------------------------------------------- */

#cc-dashboard-logo {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
}

.navbar {
  display: none;
  font-size: 16px;
  /* border-bottom: 3px solid $cc-color-continuity-pulse; */
  box-shadow: $navbar-box-shadow;

  .btn {
    font-size: 16px;
  }

  &.bg-dark {
    background-color: $navbar-background-dark!important;
  }

  &.bg-light {
    background-color: $navbar-background-light!important;
  }

  .btn-primary {
    background-color: $cc-color-continuity-pulse;
    border-color: $cc-color-continuity-pulse;
  }

  .nav-link {
    &.btn {
      padding: .375rem .75rem;
    }
  }

  .navbar-brand {
    background-color: transparent;
    box-shadow: none;
  }
}

/* Sidebar ------------------------------------------------------------------ */

#cc-pulse-logo-container {
  padding-bottom: 1em;
  border-bottom-color: $cc-interface-color-sidebar-heading-border-bottom!important;
}

#cc-pulse-logo {
  position: relative;
  width: 180px;
  margin-top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

#cc-panel-log-out-button {
  display: block;
  border-radius: 0;
}

.sidebar {
  font-size: 16px;
  background-color: $cc-color-oha;
  box-shadow: $navbar-box-shadow;
}

.sidebar-sticky {
  top: 0px;
  height: calc(100vh - 118px - 9em);
  position: relative;
  //display: none;

  .sidebar-heading {
    font-size: 1rem;
    color: $cc-color-workforce!important;
    border-bottom: 1px solid $cc-interface-color-sidebar-heading-border-bottom;
    text-transform: none;
  }

  .nav-link {
    color: $cc-color-strategy;
    transition: background-color 0.5s, color 0.5s;

    &:hover {
      color: $cc-color-oha;
      background-color: $cc-color-pulse;

      .feather {
        color: $cc-color-oha;
      }
    }

    &.active {
      color: $cc-color-oha;
      background-color: $cc-color-management;

      &:hover {
        color: $cc-color-oha;

        .feather {
          color: $cc-color-oha;
        }
      }

      .feather {
        color: $cc-color-oha;
      }
    }

    .feather {
      color: $cc-color-strategy;
      position: relative;
      top: -2px;
      transition: color 0.5s;
    }
  }

  .oi {
    top: -3px;
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
    margin-right: 4px;
  }
}

#di-branding {
  color: $cc-color-strategy;
  border-top: 1px solid $cc-interface-color-sidebar-heading-border-bottom;
  margin-left: 1.7em;
  margin-right: 1.7em;
  margin-top: 1em;
  text-align: center;
  padding-top: 0.5em;
  opacity: 0.65;

  p {
    margin: 0;
  }

  a {
    color: $cc-color-strategy;
    font-weight: bold;

    &:hover {
      text-decoration: none;
    }
  }
}

/* Main --------------------------------------------------------------------- */

main {
  font-weight: 300;
  color: $dark-text;
  //display: none;

  .container {
    background-color: $light-background;
    border: 1px solid $container-border-color;
    border-radius: 0;
    padding: 30px;
    box-shadow: $standard-shadow;
  }

  .container-fluid {
  }

  .nav-pills {
    .nav-link {
      color: $dark-text;
      background-color: $sidebar-background;
      border-color: $sidebar-background;

      &.active {
        background-color: $cc-color-continuity-pulse;
        border-color: $cc-color-continuity-pulse;
      }

      &:hover {
        color: $light-text;
        background-color: $cc-color-continuity-pulse;
        border-color: $cc-color-continuity-pulse;
      }
    }
  }
}

.cc-main-header-bar {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
  background-color: $footer-bar-background;

  padding: 1rem 1.5rem;

  h1 {
    margin-bottom: 0;
  }
}

.cc-main-header-bar-padder {
  width: 100%;
  height: 119px;
}

.cc-main-footer-bar {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: $footer-bar-background;
  padding: 1em;
  border-top: 1px solid $footer-border-color;

  .cc-btn-next {
    float: right;
  }
}

.cc-main-footer-bar-padder {
  width: 100%;
  height: calc(2em + 62.25px);
}

/* Tables ------------------------------------------------------------------- */

.cc-table-inline-button {
  padding: 2px 8px;
  font-size: .875rem;
}

/* Cards -------------------------------------------------------------------- */

.cc-stream-card,
.cc-admin-card {
  margin: 3em 0;
  border-radius: 0;
  box-shadow: $standard-shadow;

  .cc-stream-card-subheader {
    margin-bottom: 1.2em;
    padding-bottom: 0.4em;
    border-bottom: 1px solid $footer-border-color!important;
  }

  .cc-process-widget {
    padding-bottom: 1.6em;
    margin-bottom: 1.25em;
    border-bottom: 1px solid $footer-border-color!important;

    a {
      width: 100%;

      &:before {
        border-left-color: $light-background;
      }
    }
  }

  .card-title {
    margin-bottom: 0;
    font-weight: 300;
  }

  .card-body {
    .cc-operation-btn {
      margin-bottom: 1em;
    }
  }

  &.card {
    .cc-accordion-wrapper {
      .card-header {
        padding: 0.75rem 1.25rem;
  
        .card-title {
          text-align: left;
        }
      }
    }
  }
}

/* Bucket Views ------------------------------------------------------------- */

.cc-project {
  .cc-project-description {
    max-height: 10.5em;
    overflow: hidden;
  }

  .cc-card-image {
    img {
      padding-bottom: 0;
    }
  }
}

.cc-stream {
  .cc-stream-subheader {
    margin-bottom: 1.2em;
    margin-top: 2em;
    padding-bottom: 0.4em;
    border-bottom: 1px solid $footer-border-color!important;
  }

  .cc-process-widget {
    a {
      width: 100%;
    }

    .cc-phase-preview-body {
      padding: 0;
      padding-top: 0.75em;
      margin-top: 2em;
      border-top: 1px solid $footer-border-color!important;

      .card {
        border-radius: 0;
        margin-bottom: 1em;
        padding: 1em;
        box-shadow: $standard-shadow;
      }
    }
  }
}

.cc-stream-description {
  /* margin-top: 1em; */
}

.cc-stream-subheader,
.cc-phase-subheader {
  margin-bottom: 1em;
  margin-top: 2em;
}

.cc-phase-view-container {
  margin-bottom: 8em;
}

.cc-phase-preview-block {
  color: $light-background;
  font-size: 3em;
  padding: 2em 1em;
  text-align: center;
  margin: 0;
  margin-bottom: 1em;
  box-shadow: $preview-box-shadow;

  &:hover {
    color: $light-background;
  }

  &.btn {
    display: block;
    border-radius: 0;
  }

  &.cc-phase-preview-block-first {
    padding-right: 0;
    margin-right: 1em;

    &:after {
      content: "";
      
      display: block;
      position: absolute;
      
      right: 0.21em;
      top: 0;
      width: 0;
      height: 0;

      border-top: 2.76em solid transparent;
      border-bottom: 2.76em solid transparent;
      border-left: 1em solid $dark-background;

      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
  }

  &.cc-phase-preview-block-middle {
    padding-right: 0;
    margin-right: 1em;

    padding-left: 1.5em;

    &:before {
      content: "";
      
      display: block;
      position: absolute;
      
      left: 0.21em;
      top: 0;
      width: 0;
      height: 0;

      border-top: 2.76em solid transparent;
      border-bottom: 2.76em solid transparent;
      border-left: 1em solid $footer-bar-background;

      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

    &:after {
      content: "";
      
      display: block;
      position: absolute;
      
      right: 0.21em;
      top: 0;
      width: 0;
      height: 0;

      border-top: 2.76em solid transparent;
      border-bottom: 2.76em solid transparent;
      border-left: 1em solid $dark-background;

      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
  }

  &.cc-phase-preview-block-last {
    padding-left: 1.5em;

    &:before {
      content: "";
      
      display: block;
      position: absolute;
      
      left: 0.21em;
      top: 0;
      width: 0;
      height: 0;

      border-top: 2.76em solid transparent;
      border-bottom: 2.76em solid transparent;
      border-left: 1em solid $footer-bar-background;

      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
  }

  &.cc-phase-preview-plan {
    background-color: $phase-preview-background;

    &:before {
      border-left: 1em solid $footer-bar-background;
    }

    &:after {
      border-left: 1em solid $phase-preview-background;
    }

    &:hover {
      background-color: $phase-preview-background-hover;

      &:before {
        border-left-color: $footer-bar-background;
      }

      &:after {
        border-left-color: $phase-preview-background-hover; 
      }
    }
  }

  &.cc-phase-preview-discover {
    background-color: $phase-preview-background-discover;

    &:before {
      border-left: 1em solid $footer-bar-background;
    }

    &:after {
      border-left: 1em solid $phase-preview-background-discover;
    }

    &:hover {
      background-color: $phase-preview-background-discover-hover;

      &:before {
        border-left-color: $footer-bar-background;
      }

      &:after {
        border-left-color: $phase-preview-background-discover-hover;
      }
    }
  }

  &.cc-phase-preview-develop {
    background-color: $phase-preview-background-develop;

    &:before {
      border-left: 1em solid $footer-bar-background;
    }

    &:after {
      border-left: 1em solid $phase-preview-background-develop;
    }

    &:hover {
      background-color: $phase-preview-background-develop-hover;

      &:before {
        border-left-color: $footer-bar-background;
      }

      &:after {
        border-left-color: $phase-preview-background-develop-hover;
      }
    }
  }

  &.cc-phase-preview-implement {
    background-color: $phase-preview-background-implement;

    &:before {
      border-left: 1em solid $footer-bar-background;
    }

    &:after {
      border-left: 1em solid $phase-preview-background-implement;
    }

    &:hover {
      background-color: $phase-preview-background-implement-hover;

      &:before {
        border-left-color: $footer-bar-background;
      }

      &:after {
        border-left-color: $phase-preview-background-implement-hover;
      }
    }
  }
}

.cc-phase-preview-body {
  padding: 0 1em;
}

.cc-phase-type-nav {
  padding-bottom: 1em;
}

/* Process Widget ----------------------------------------------------------- */

.cc-process-widget {
  margin-bottom: 0;

  .btn {
    border-radius: 0;
  }

  .dropdown {
    display: inline-block;

    .dropdown-toggle {
      margin-right: 0.8em;
      margin-left: 0.4em;

      color: $light-background;
      background-color: $cc-color-continuity-pulse;
      border-color: $cc-color-continuity-pulse;

      &.cc-process-widget-first,
      &.cc-process-widget-middle {
        &:after {
          margin-left: initial;
          vertical-align: initial;
          border-right: initial;
  
          content: "";
  
          display: block;
          position: absolute;
          
          left: calc(100% + 1px);
          top: -1px;
          width: 0;
          height: 0;
          
          border-top: 31px solid transparent;
          border-bottom: 31px solid transparent;
          border-left: 1em solid $cc-color-continuity-pulse;
          
          transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }
      }
      &.cc-process-widget-last {
        &:after {
          display: none;
        }
      }
    }

    .feather {
      width: 1em;
      height: 1em;
      position: relative;
      top: -2px;
    }

    .dropdown-menu {
      min-width: calc(100% - 0.6em);
      margin-right: 0.6em;
      border-radius: 0;
    }
  }

  .cc-process-widget-btn {
    position: relative;
    margin-right: 0.4em;
    background-color: $sidebar-background;
    border-color: $sidebar-background;
    color: $sidebar-nav-color-hover;

    &:focus {
      box-shadow: none;
    }

    &:hover {
      background-color: $cc-process-widget-btn-background;
      border-color: $cc-process-widget-btn-background;
      color: $light-background;

      &:after {
        border-left-color: $cc-process-widget-btn-background!important;
      }
    }

    &.btn-primary:not(:disabled):not(.disabled).active,
    &.btn-primary:not(:disabled):not(.disabled):active,
    &.show > .btn-primary.dropdown-toggle {
      background-color: $cc-process-widget-btn-background;
      border-color: $cc-process-widget-btn-background;
      box-shadow: none;

      &:after {
        border-left-color: $cc-process-widget-btn-background!important;
      }
    }

    &.cc-process-widget-inert {
      color: $sidebar-nav-color-hover;
      cursor: default;

      &:hover {
        background-color: $sidebar-background;
        border-color: $sidebar-background;

        &:after {
          border-left-color: $sidebar-background!important;
        }
      }
    }
  }

  .cc-process-widget-filled {
    background-color: $cc-color-continuity-pulse;
    border-color: $cc-color-continuity-pulse;
    color: $light-background;

    &:after {
      border-left-color: $cc-color-continuity-pulse!important;
    }
  }

  .cc-process-widget-first {
    &:after {
      content: "";

      display: block;
      position: absolute;
      
      left: calc(100% + 1px);
      top: -1px;
      width: 0;
      height: 0;
      
      border-top: 31px solid transparent;
      border-bottom: 31px solid transparent;
      border-left: 1em solid $sidebar-background;
      
      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
  }

  .cc-process-widget-middle {
    padding-left: 44px;

    &:before {
      content: "";
      
      display: block;
      position: absolute;
      
      left: -1px;
      top: -1px;
      width: 0;
      height: 0;

      border-top: 31px solid transparent;
      border-bottom: 31px solid transparent;
      border-left: 1em solid $footer-bar-background;

      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

    &:after {
      content: "";

      display: block;
      position: absolute;
      
      left: calc(100% + 1px);
      top: -1px;
      width: 0;
      height: 0;
      
      border-top: 31px solid transparent;
      border-bottom: 31px solid transparent;
      border-left: 1em solid $sidebar-background;
      
      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
  }

  .cc-process-widget-last {
    padding-left: 44px;

    &:before {
      content: "";
      
      display: block;
      position: absolute;
      
      left: -1px;
      top: -1px;
      width: 0;
      height: 0;

      border-top: 31px solid transparent;
      border-bottom: 31px solid transparent;
      border-left: 1em solid $footer-bar-background;

      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
  }
}

/* Path Widget -------------------------------------------------------------- */

.cc-path-widget {
  .feather {
    position: relative;
    top: -0.05em;
    width: 1em;
    height: 1em;
    margin-left: 0.25em;
    margin-right: 0.25em;
  }

  h1, h2, h3, h4, h5, p {
    margin: 0;
  }
}

/* Buttons ------------------------------------------------------------------ */

.cc-btn-previous {
  position: relative;
  border-radius: 0;
  margin-left: 1em;
  margin-right: 0.25em;

  &:before {
    content: "";

    display: block;
    position: absolute;
    
    right: calc(100% + 1px);
    top: -1px;
    width: 0;
    height: 0;
    
    border-top: 31px solid transparent;
    border-bottom: 31px solid transparent;
    border-right: 1em solid $cc-color-continuity-pulse;
    
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  &:focus {
    box-shadow: none;
  }

  &:hover {
    background-color: $cc-process-widget-btn-background;
    border-color: $cc-process-widget-btn-background;

    &:before {
      border-right-color: $cc-process-widget-btn-background!important;
    }
  }

  &.btn-primary:not(:disabled):not(.disabled).active,
  &.btn-primary:not(:disabled):not(.disabled):active,
  &.show > .btn-primary.dropdown-toggle {
    background-color: $cc-process-widget-btn-background;
    border-color: $cc-process-widget-btn-background;
    box-shadow: none;

    &:before {
      border-right-color: $cc-process-widget-btn-background!important;
    }
  }
}

.cc-btn-next {
  position: relative;
  border-radius: 0;
  margin-left: 0.25em;
  margin-right: 1em;

  &:after {
    content: "";

    display: block;
    position: absolute;
    
    left: calc(100% + 1px);
    top: -1px;
    width: 0;
    height: 0;
    
    border-top: 31px solid transparent;
    border-bottom: 31px solid transparent;
    border-left: 1em solid $cc-color-continuity-pulse;
    
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }

  &:focus {
    box-shadow: none;
  }

  &:hover {
    background-color: $cc-process-widget-btn-background;
    border-color: $cc-process-widget-btn-background;

    &:after {
      border-left-color: $cc-process-widget-btn-background!important;
    }
  }

  &.btn-primary:not(:disabled):not(.disabled).active,
  &.btn-primary:not(:disabled):not(.disabled):active,
  &.show > .btn-primary.dropdown-toggle {
    background-color: $cc-process-widget-btn-background;
    border-color: $cc-process-widget-btn-background;
    box-shadow: none;

    &:after {
      border-left-color: $cc-process-widget-btn-background!important;
    }
  }
}

/* Forms -------------------------------------------------------------------- */

.cc-user-form {
  .form-group {
    @media (min-width: 1200px) {
      position: relative;
      height: 100%;
    }

    .cc-profile-image-label {
      text-align: center;
      width: 100%;
      font-size: 2em;
      margin-bottom: .75rem;
    }
  
    .cc-profile-image {
      display: block;
      margin: 0 auto;
      width: 100%;
      max-width: 350px;
      max-height: 350px;
    }

    .cc-profile-image-upload {
      display: block;
      width: 225px;
      margin: 0 auto;
      margin-top: 0.92em;
      font-size: 0.875rem;
    }

    .cc-profile-image-submit {
      display: block;
      width: 225px;
      margin: 0 auto;
      margin-top: 0.5em;
      font-size: 0.875rem;
    }

    @media (min-width: 1200px) {
      .cc-profile-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    @media (min-width: 1200px) and (max-width: 2029px) {
      .cc-profile-image-upload {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 2em;
      }

      .cc-profile-image-submit {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
      }
    }
  
    @media (min-width: 2030px) {
      .cc-profile-image-upload {
        position: absolute;
        width: calc(50% - 0.5em);
        left: 0;
        bottom: 0;
        padding-top: 0.85em;
        padding-bottom: 0.85em;
      }
    
      .cc-profile-image-submit {
        position: absolute;
        width: calc(50% - 0.5em);
        right: 0;
        bottom: 0;
        padding-top: 0.85em;
        padding-bottom: 0.85em;
      }
    }
  }

  label {
    font-size: 1.5em;
  }

  .cc-user-form-fields {
    margin-top: 0.7em;
  }

  .cc-user-form-permissions-table {
    .row {
      margin-left: 0;
      margin-right: 0;
    }

    .form-control {
      position: relative;
      top: 50%;
      transform: translateY(-50%);

      &:focus {
        box-shadow: none;
      }
    }

    .cc-table-header {
      text-align: center;
      font-weight: bold;
      padding-bottom: 1em;
    }


    .cc-table-body {
      .cc-indent-single {
        padding-left: 2em;
      }

      .cc-indent-double {
        padding-left: 4em;
      }

      .cc-indent-triple {
        padding-left: 6em;
      }

      .row {
        padding: 0.25em 0;
      }

      .col-sm-2 {
        padding-top: 1em;
      }
    }
  }
}

.cc-forgot-password-link {
  font-size: 14px;
}

#question-filter-form
{
  margin-bottom: 1rem;

  .form-group
  {
    border-bottom: 1px solid #b7bfc6;
    padding-bottom: 1rem;
  }
}